// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext.js';  // Import AuthProvider
import LoginForm from './Components/LoginForm';
import ForgotPassword from './Components/ForgotPassword';
import YearSelectPage from './Components/YearSelectPage';
import CompanyDetailsPage from './Components/CompanyDetailsPage';
import DashboardPage from './Components/Dashboard/DashboardPage';
import Sidebar from './Components/Dashboard/SideBar';
import Outstanding from './Components/Dashboard/Outstanding';
import DataTable from './Components/DataTable';
import ChangePassword from './Components/Dashboard/ChangePassword';
import StockReport from './Components/Dashboard/StockReport';
import Stock from './Components/Stock';
import ProtectedRoute from './context/ProtectedRoute.js';  // Import ProtectedRoute
import SalesChart from './Components/Dashboard/SalesChart.js';
import PurchaseChart from './Components/Dashboard/PurchaseChart.js';
import PaymentChart from './Components/Dashboard/PaymentChart.js';
import RecepitChart from './Components/Dashboard/RecepitChart.js';

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          {/* Public routes */}
          <Route path="/" element={<LoginForm />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/year-select" element={<ProtectedRoute><YearSelectPage /></ProtectedRoute>} />
          <Route path="/company-details" element={<ProtectedRoute><CompanyDetailsPage /></ProtectedRoute>} />

          {/* Protected routes */}
          <Route path="/dashboard" element={<ProtectedRoute><DashboardPage /></ProtectedRoute>} />
          <Route path="/dashboard/sidebar" element={<ProtectedRoute><Sidebar /></ProtectedRoute>} />
          <Route path="/outstanding" element={<ProtectedRoute><Outstanding /></ProtectedRoute>} />
          <Route path="/dataTable" element={<ProtectedRoute><DataTable /></ProtectedRoute>} />
          <Route path="/change-password" element={<ProtectedRoute><ChangePassword /></ProtectedRoute>} />
          <Route path="/stock-report" element={<ProtectedRoute><StockReport /></ProtectedRoute>} />
          <Route path="/stock" element={<ProtectedRoute><Stock /></ProtectedRoute>} />
          <Route path="/sales-chart" element={<ProtectedRoute><SalesChart /></ProtectedRoute>} />
          <Route path="/purchase-chart" element={<ProtectedRoute><PurchaseChart /></ProtectedRoute>} />
          <Route path="/payment-chart" element={<ProtectedRoute><PaymentChart /></ProtectedRoute>} />
          <Route path="/recepit -chart" element={<ProtectedRoute><RecepitChart /></ProtectedRoute>} />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;
