// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Card.css */
.card {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    margin: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    flex: 1 1; /* Makes cards responsive */
  }
  `, "",{"version":3,"sources":["webpack://./src/Components/css/Card.css"],"names":[],"mappings":"AAAA,aAAa;AACb;IACI,sBAAsB;IACtB,kBAAkB;IAClB,aAAa;IACb,YAAY;IACZ,wCAAwC;IACxC,SAAO,EAAE,2BAA2B;EACtC","sourcesContent":["/* Card.css */\n.card {\n    border: 1px solid #ccc;\n    border-radius: 8px;\n    padding: 20px;\n    margin: 10px;\n    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);\n    flex: 1; /* Makes cards responsive */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
