import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './css/YearSelectPage.css';

const YearSelectPage = () => {
  const [financialYears, setFinancialYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchFinancialYears() {
      setLoading(true);
      try {
        const accessToken = localStorage.getItem('accessToken');
        const userId = localStorage.getItem('userId');

        const response = await fetch(`${process.env.REACT_APP_API_URL}/financial-years`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
            'UserId': userId ? userId : '',
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setFinancialYears(data);
        setSelectedYear(data.length > 0 ? data[0].fyear : '');
      } catch (error) {
        console.error('Error fetching financial years:', error);
      } finally {
        setLoading(false);
      }
    }

    fetchFinancialYears();
  }, []);

  const handleChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const [fromDate, toDate] = selectedYear.split(' TO ');
    navigate(`/company-details?fromDate=${encodeURIComponent(fromDate)}&toDate=${encodeURIComponent(toDate)}`);
  };

  return (
    <div className="year-select-container">
      <h1 className="message">Select a Financial Year</h1>
      <form className="year-select-form" onSubmit={handleSubmit}>
        <label className="year-select-label" htmlFor="year-select">Financial Year:</label>
        <select className="year-select" id="year-select" value={selectedYear} onChange={handleChange}>
          {financialYears.map((year, index) => (
            <option key={index} value={year.fyear}>
              {year.fyear}
            </option>
          ))}
        </select>
        <button className="submit-button" type="submit" disabled={loading}>
          {loading ? 'Loading...' : 'Submit'}
        </button>
      </form>
    </div>
  );
};

export default YearSelectPage;
