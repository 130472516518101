import React, { useState, useEffect, useMemo } from 'react';
import '../Components/css/Stock.css';
import { useLocation, useNavigate } from 'react-router-dom';

const Stock = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState([]); // Store fetched data
  const [loading, setLoading] = useState(true); // Manage loading state
  const [error, setError] = useState(null); // Manage error state
  const [paginationInfo, setPaginationInfo] = useState({
    currentPage: 1,
    itemsPerPage: 20,
  }); // Pagination state
  const [expandedRows, setExpandedRows] = useState({}); // Track expanded rows
  const [departmentFilter, setDepartmentFilter] = useState(''); // Department filter state
  const [searchQuery, setSearchQuery] = useState(''); // Search query state

  // Extract query parameters from URL
  const queryParams = new URLSearchParams(location.search);
  const departmentCode = queryParams.get('departmentcode') || '';
  const groupCode = queryParams.get('groupcode') || '';
  const brandCode = queryParams.get('brandcode') || '';
  const designCode = queryParams.get('designcode') || '';
  const colorCode = queryParams.get('colorcode') || '';
  const sizeCode = queryParams.get('sizecode') || '';
  const patternCode = queryParams.get('patterncode') || '';
  const seasonCode = queryParams.get('seasoncode') || '';

  // Compute the grand total
  const grandTotal = useMemo(() => {
    return data.reduce((total, row) => {
      const grandTotalValue = parseFloat(row['Grand Total']) || 0; // Ensure it's a number
      return total - total + grandTotalValue;
    }, 0);
  }, [data]);

  // Fetch data from API
  useEffect(() => {
    const fetchData = async () => {
      const userId = localStorage.getItem('userId');
      const accessToken = localStorage.getItem('accessToken');
      const selectedCompany = JSON.parse(localStorage.getItem('selectedCompany'));

      if (!selectedCompany || !selectedCompany.CompCode) {
        setError('Company code is missing or invalid');
        setLoading(false);
        return;
      }

      const compCode = selectedCompany.CompCode;

      if (!userId || !accessToken) {
        setError('Missing userId or accessToken in localStorage');
        setLoading(false);
        return;
      }

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/createItemProcedure?CompCode=${compCode}&departmentcode=${departmentCode}&groupcode=${groupCode}&brandcode=${brandCode}&designcode=${designCode}&colorcode=${colorCode}&sizecode=${sizeCode}&patterncode=${patternCode}&seasoncode=${seasonCode}`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'UserId': userId,
              'Authorization': `Bearer ${accessToken}`,
            },
          }
        );

        const responseBody = await response.json();
        console.log(responseBody); // Log the response for debugging

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        if (!responseBody || responseBody.data.length === 0) {
          setError('No data available');
        } else {
          setData(responseBody.data); // Set fetched data to the state
        }
      } catch (error) {
        console.error('Error fetching data:', error); // Log actual error for debugging
        setError('Error fetching data, please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [departmentCode, groupCode, brandCode, designCode, colorCode, sizeCode, patternCode, seasonCode]); // Re-fetch when query params change

  // Memoize table headers (dynamically based on the first item in the data array)
  const getTableHeaders = useMemo(() => {
    if (data.length > 0) {
      const allHeaders = Object.keys(data[0]);
      const requiredHeaders = ['DEPARTMENT', 'GROUPNAME', 'BRAND', 'DESGIN', 'COLOR', 'PATTERN', 'SEASON', 'Grand Total'];
      const customColumns = requiredHeaders.filter(header => allHeaders.includes(header));
      return [...customColumns, ...allHeaders.filter(header => !customColumns.includes(header))];
    }
    return [];
  }, [data]);

  // Get unique departments dynamically from the data
  const departmentOptions = useMemo(() => {
    const departments = data.map(item => item.DEPARTMENT);
    return [...new Set(departments)]; // Remove duplicates by using Set
  }, [data]);

  // Filter data based on department and search query
  const filteredData = useMemo(() => {
    let filtered = data;

    // Filter by department
    if (departmentFilter) {
      filtered = filtered.filter(item => item.DEPARTMENT === departmentFilter);
    }

    // Filter by search query across selected fields
    if (searchQuery) {
      const searchLower = searchQuery.toLowerCase();
      filtered = filtered.filter((item) =>
        ['GROUPNAME', 'BRAND', 'DESGIN', 'COLOR', 'PATTERN'].some((key) =>
          item[key] && item[key].toLowerCase().includes(searchLower)
        )
      );
    }

    return filtered;
  }, [data, departmentFilter, searchQuery]);

  // Paginate the filtered data
  const totalPages = Math.ceil(filteredData.length / paginationInfo.itemsPerPage);

  // Get the data for the current page
  const currentPageData = useMemo(() => {
    const startIndex = (paginationInfo.currentPage - 1) * paginationInfo.itemsPerPage;
    const endIndex = startIndex + paginationInfo.itemsPerPage;
    return filteredData.slice(startIndex, endIndex);
  }, [filteredData, paginationInfo]);

  // Handle page change (Previous and Next)
  const handlePageChange = (direction) => {
    setPaginationInfo((prev) => {
      const newPage =
        direction === 'next' ? prev.currentPage + 1 : prev.currentPage - 1;
      return {
        ...prev,
        currentPage: Math.max(1, Math.min(newPage, totalPages)), // Ensure page is within bounds
      };
    });
  };

  // Handle department filter change
  const handleDepartmentChange = (event) => {
    setDepartmentFilter(event.target.value);
    setPaginationInfo({ currentPage: 1, itemsPerPage: 23 }); // Reset to first page
  };

  // Handle search input change
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setPaginationInfo({ currentPage: 1, itemsPerPage: 23 }); // Reset to first page
  };

  // Toggle expanded row
  const toggleRowExpansion = (rowIndex) => {
    setExpandedRows((prev) => ({
      ...prev,
      [rowIndex]: !prev[rowIndex], // Toggle the expanded state for the clicked row
    }));
  };

  return (
    <div className="stock-table-container">
      <button className="back-button" onClick={() => navigate(-1)}>Back</button>
      <div className='stock-heading'>
        <h1>Stock Report</h1>
        {grandTotal > 0 && (
          <p className="grand-total-text">Grand Total: {grandTotal}</p>  
        )}
      </div>

      {/* Show loading message */}
      {loading && <p>Loading...</p>}

      {/* Show error message if an error occurs */}
      {error && <p className="error">{error}</p>}

      {/* Department filter dropdown */}
      <div className="column-selection">
        <div className="select-container">
          <label htmlFor="department">Select:</label>
          <select id="department" value={departmentFilter} onChange={handleDepartmentChange}>
            <option value="">All Departments</option>
            {departmentOptions.map((department, index) => (
              <option key={index} value={department}>
                {department}
              </option>
            ))}
          </select>
        </div>
        <div className="search-container">
          <label htmlFor="search">Search:</label>
          <input
            type="text"
            id="search"
            placeholder="Search GROUPNAME, BRAND, DESIGN, COLOR, PATTERN..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </div>
      </div>

      {/* Render table if data is available */}
      {!loading && !error && filteredData.length > 0 && (
        <div className="data-table-wrapper">
          <table className="stock-data-table">
            <thead>
              <tr>
                {/* Render the required headers in the main table */}
                {getTableHeaders.slice(0, 8).map((header, index) => (
                  <th key={index} className="stock-data-table-header">
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {/* Dynamically generate table rows */}
              {currentPageData.map((row, rowIndex) => (
                <React.Fragment key={rowIndex}>
                  <tr>
                    {/* Render the department with the plus/minus button */}
                    <td className="stock-data-table-cell">
                      <button
                        onClick={() => toggleRowExpansion(rowIndex)}
                        className="expand-button"
                      >
                        {expandedRows[rowIndex] ? '-' : '+'}
                      </button>
                      {row['DEPARTMENT']}
                    </td>

                    {/* Render the required columns */}
                    {getTableHeaders.slice(1, 8).map((header, colIndex) => {
                      const showColumn = row[header] !== null && row[header] !== '-';
                      return (
                        showColumn && (
                          <td key={colIndex} className="stock-data-table-cell">
                            {row[header] !== null ? row[header] : '-'}
                          </td>
                        )
                      );
                    })}
                  </tr>

                  {/* Render additional columns only when expanded and they have values */}
                  {expandedRows[rowIndex] && row['Grand Total'] && (
                    <tr>
                      {/* Render headers of expanded columns */}
                      {getTableHeaders.slice(8).map((header, colIndex) => {
                        if (row[header] !== null && row[header] !== '-') {
                          return (
                            <th key={colIndex} className="stock-data-table-header">
                              {header}
                            </th>
                          );
                        }
                        return null;
                      })}
                    </tr>
                  )}

                  {/* Render the corresponding data for the expanded columns */}
                  {expandedRows[rowIndex] && (
                    <tr>
                      {getTableHeaders.slice(8).map((header, colIndex) => {
                        if (row[header] !== null && row[header] !== '-') {
                          return (
                            <td key={colIndex} className="stock-data-table-cell">
                              {row[header]}
                            </td>
                          );
                        }
                        return null;
                      })}
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* Show message when no data is available */}
      {!loading && !error && filteredData.length === 0 && <p>No data available</p>}

      <div className="pagination-controls">
        {/* Start text with underline */}
        <span
          onClick={() => setPaginationInfo((prev) => ({ ...prev, currentPage: 1 }))}
          className={`pagination-link ${paginationInfo.currentPage === 1 ? 'disabled' : ''}`}
        >
          Start
        </span>

        {/* Previous button */}
        <button
          onClick={() => handlePageChange('prev')}
          disabled={paginationInfo.currentPage === 1}
          className="pagination-button"
        >
          &#8592;
        </button>

        {/* Page info */}
        <span className="pagination-info">
          Page {paginationInfo.currentPage} of {totalPages}
        </span>

        {/* Next button */}
        <button
          onClick={() => handlePageChange('next')}
          disabled={paginationInfo.currentPage === totalPages}
          className="pagination-button"
        >
          &#8594;
        </button>

        {/* End text with underline */}
        <span
          onClick={() => setPaginationInfo((prev) => ({ ...prev, currentPage: totalPages }))}
          className={`pagination-link ${paginationInfo.currentPage === totalPages ? 'disabled' : ''}`}
        >
          End
        </span>
      </div>
    </div>
  );
};

export default Stock;
