import React, { useState, useEffect, useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import Lottie from 'react-lottie';
import loaderAnimation from '../css/loader2.json';
import '../css/SaleChart.css';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const PurchaseChart = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [interval, setInterval] = useState('daily'); // Default to daily
  const chartRef = useRef(null);

  const fetchData = async (interval) => {
    const userId = localStorage.getItem('userId');
    const accessToken = localStorage.getItem('accessToken');
    const selectedCompany = JSON.parse(localStorage.getItem('selectedCompany'));

    if (!selectedCompany || !selectedCompany.CompCode) {
      setError('Company code is missing or invalid');
      setLoading(false);
      return;
    }

    const compCode = selectedCompany.CompCode;

    if (!userId || !accessToken) {
      setError('Missing userId or accessToken in localStorage');
      setLoading(false);
      return;
    }

    try {
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), 90000); // Set timeout to 90 seconds

      let apiUrls = [];
      switch (interval) {
        case 'daily':
          apiUrls = [`${process.env.REACT_APP_API_URL}/DailySummary?CompCode=${compCode}&type=purch`];
          break;
        case 'weekly':
          apiUrls = [
            `${process.env.REACT_APP_API_URL}/WeeklySummary?CompCode=${compCode}&type=purch`  // Weekly data (6 weeks)
          ];
          break;
        case 'monthly':
          apiUrls = [
            `${process.env.REACT_APP_API_URL}/MonthlySummary?CompCode=${compCode}&type=purch`  // Monthly data (12 months)
          ];
          break;
        default:
          throw new Error('Unknown interval selected');
      }

      // Fetch all the APIs
      const fetchPromises = apiUrls.map(url =>
        fetch(`${url}&_=${new Date().getTime()}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'UserId': userId,
            'Authorization': `Bearer ${accessToken}`,
          },
          signal: controller.signal,
        })
      );

      const responses = await Promise.all(fetchPromises);
      clearTimeout(timeoutId);

      // Process responses
      const responseBodies = await Promise.all(responses.map(response => response.json()));

      // Check for errors or no data
      if (responses.some(response => !response.ok)) {
        throw new Error('Error fetching one or more APIs');
      }

      // Combine data from all APIs based on the selected interval
      let combinedData = [];
      responseBodies.forEach(responseBody => {
        if (responseBody && responseBody.length > 0) {
          combinedData = [...combinedData, ...responseBody];
        }
      });

      if (combinedData.length === 0) {
        setError('No data available');
      } else {
        setData(combinedData); // Set fetched data to the state
      }
    } catch (error) {
      if (error.name === 'AbortError') {
        setError('The request timed out. Please try again.');
      } else {
        setError('Error fetching data, please try again later.');
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(interval);
  }, [interval]);

  const handleDropdownChange = (e) => {
    const selectedInterval = e.target.value;
    setInterval(selectedInterval);
  };

  if (loading) {
    return (
      <div className="loader-container">
        <Lottie options={{ animationData: loaderAnimation, loop: true, autoplay: true }} height={100} width={100} />
      </div>
    );
  }

  if (error) return <div>{error}</div>;

  // Function to map full weekday name to short format (e.g., Monday -> Mon)
  const getShortDayName = (fullDayName) => {
    const dayMap = {
      Monday: 'Mon',
      Tuesday: 'Tue',
      Wednesday: 'Wed',
      Thursday: 'Thu',
      Friday: 'Fri',
      Saturday: 'Sat',
      Sunday: 'Sun',
    };
    return dayMap[fullDayName] || fullDayName;
  };

  // Map the 'ldate' (full weekday names) to short day names for daily data
  const labels = data.map(item => getShortDayName(item.ldate));

  const amounts = data.map(item => isNaN(item.amt) ? 0 : item.amt);   // Sanitize purchase amounts

  const formatNumberWithCommas = (number) => {
    if (number === undefined || number === null) {
      return ''; // Return empty if value is invalid
    }
    return number.toLocaleString(); // Apply number formatting
  };

  const maxAmount = Math.max(...amounts);
  const containerHeight = chartRef.current ? chartRef.current.clientHeight : 285;
  const stepSize = Math.max(Math.floor(maxAmount / (containerHeight / 50)), 1);

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: 'Purchases',
        data: amounts,
        backgroundColor: 'rgba(54, 162, 235, 0.6)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 2,
        type: 'bar',
        datalabels: {
          align: 'top',
          font: {
            weight: 'normal',
            size: 12,
            family: 'Arial',
          },
          color: '#333',
          formatter: (value) => formatNumberWithCommas(value), // Safe formatting
        },
      },
      {
        label: 'Line',
        data: amounts,
        borderColor: 'rgba(255, 99, 132, 1)',
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        tension: 0.4,
        fill: true,
        type: 'line',
        pointRadius: 5,
        pointBackgroundColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 3,
        datalabels: {
          display: false,
        },
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: `Purchase Summary for the Last ${interval.charAt(0).toUpperCase() + interval.slice(1)}`,
        font: {
          size: 15,
          weight: 'bold',
          family: 'Arial, sans-serif',
        },
        padding: {
          top: 10,
          bottom: 0,
        },
      },
      tooltip: {
        mode: 'index',
        intersect: false,
      },
      legend: {
        position: 'top',
      },
      datalabels: {
        display: true,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Period',
        },
        ticks: {
          maxRotation: 45,
          minRotation: 0,
          padding: 10,
        },
      },
      y: {
        title: {
          display: true,
          text: 'Amount',
        },
        beginAtZero: true,
        max: maxAmount + (stepSize * 2),
        ticks: {
          stepSize: stepSize,
        },
      },
    },
  };

  return (
    <div>
      <div className="chart-header">
        <div className="chart-container" ref={chartRef}>
          <select value={interval} onChange={handleDropdownChange} className="chart-dropdown">
            <option value="daily">Daily</option>
            <option value="weekly">Weekly</option>
            <option value="monthly">Monthly</option>
          </select>

          <Bar
            data={chartData}
            options={options}
            style={{ borderRadius: '10px', overflow: 'hidden', height: '200px', width: '500px' }}
          />
        </div>
      </div>
    </div>
  );
};

export default PurchaseChart;
