import React, { useState } from 'react';
import './css/ForgotPassword.css'; // Add styles for this page if needed

const ForgotPassword = () => {
  const [resetEmail, setResetEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleResetPassword = (event) => {
    event.preventDefault();

    if (!resetEmail) {
      setError('Please enter your email address.');
      return;
    }

    // Simulate a password reset request
    console.log('Reset email:', resetEmail);

    // Reset email success
    setSuccess('A password reset link has been sent to your email address.');
    setError('');
    setResetEmail(''); // Clear the email field after submission
  };

  return (
    <div className="forgot-password-container">
      <h2>Forgot Password</h2>
      <form onSubmit={handleResetPassword}>
        <div>
          <label htmlFor="reset-email">Email:</label>
          <input
            type="email"
            id="reset-email"
            value={resetEmail}
            onChange={(e) => setResetEmail(e.target.value)}
            required
          />
        </div>
        {error && <p className="error">{error}</p>}
        {success && <p className="success">{success}</p>}
        <button type="submit">Send Reset Link</button>
      </form>
    </div>
  );
};

export default ForgotPassword;
