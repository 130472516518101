import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from './SideBar';
import '../css/Outstanding.css';
import Header from '../Dashboard/Header.js'

function Outstanding() {
  const navigate = useNavigate();

  const [type, setType] = useState('Receivable');
  const [dueDays, setDueDays] = useState('0');
  const [criteria, setCriteria] = useState('datewise');
  const [operator, setOperator] = useState('');
  const [date, setDate] = useState('');
  const [partyWiseValue, setPartyWiseValue] = useState('');
  const [salesmanName, setSalesmanName] = useState('');
  const [areaWiseValue, setAreaWiseValue] = useState('');
  const [rootWiseValue, setRootWiseValue] = useState('');
  const [combinedRootValue, setCombinedRootValue] = useState('');
  const [combinedSalesmanValue, setCombinedSalesmanValue] = useState('');
  const [error, setError] = useState(null);
  const [partySuggestions, setPartySuggestions] = useState([]);
  const [salesmanSuggestions, setSalesmanSuggestions] = useState([]);
  const [areaSuggestions, setAreaSuggestions] = useState([]);
  const [rootSuggestions, setRootSuggestions] = useState([]);
  const [combinedRootSuggestions, setCombinedRootSuggestions] = useState([]);
  const [combinedSalesmanSuggestions, setCombinedSalesmanSuggestions] = useState([]);
  const [subAgentValue, setSubAgentValue] = useState('');
  const [subAgentSuggestions, setSubAgentSuggestions] = useState([]);
  const [skip, setSkip] = useState([]);


  useEffect(() => {
    const today = new Date().toISOString().split('T')[0];
    setDate(today);
  }, []);

  const handleChange = (setter) => (event) => {
    setSkip([])
    setter(event.target.value)
  };

  const fetchSubAgentSuggestions = useCallback(async () => {
    const userId = localStorage.getItem('userId');
    const accessToken = localStorage.getItem('accessToken');

    if (!subAgentValue.trim()) {
      setSubAgentSuggestions([]);
      return;
    }

    const selectedCompany = JSON.parse(localStorage.getItem('selectedCompany'));
    if (!selectedCompany || !selectedCompany.CompCode) {
      setError('Company code is missing or invalid');
      return;
    }

    const queryParams = new URLSearchParams({
      name: subAgentValue.trim().toLowerCase(),
      CompCode: selectedCompany.CompCode,
    });

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/salesman-suggestions?${queryParams.toString()}`, {
        headers: {
          'UserId': userId,
          'Authorization': `Bearer ${accessToken}`,
        },
      });


      if (!response.ok) throw new Error('Network response was not ok');

      const data = await response.json();
      setSubAgentSuggestions(data);
    } catch (error) {
      console.error('Error fetching sub-agent suggestions:', error);
      setError(error.message);
    }
  }, [subAgentValue]);


  const fetchPartySuggestions = useCallback(async () => {
    const userId = localStorage.getItem('userId');
    const accessToken = localStorage.getItem('accessToken');

    if (!partyWiseValue) {
      setPartySuggestions([]);
      return;
    }

    const selectedCompany = JSON.parse(localStorage.getItem('selectedCompany'));
    if (!selectedCompany || !selectedCompany.CompCode) {
      setError('Company code is missing or invalid');
      return;
    }

    const queryParams = new URLSearchParams({
      name: partyWiseValue,
      CompCode: selectedCompany.CompCode,
    });

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/getPartySuggestions?${queryParams.toString()}`, {
        headers: {
          'UserId': userId,
          'Authorization': `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) throw new Error('Network response was not ok');

      const data = await response.json();
      setPartySuggestions(data);
    } catch (error) {
      console.error('Error fetching party suggestions:', error);
      setError(error.message);
    }
  }, [partyWiseValue]);

  const fetchSalesmanSuggestions = useCallback(async () => {
    const userId = localStorage.getItem('userId');
    const accessToken = localStorage.getItem('accessToken');

    if (!salesmanName.trim()) {
      setSalesmanSuggestions([]);
      return;
    }

    const selectedCompany = JSON.parse(localStorage.getItem('selectedCompany'));
    if (!selectedCompany || !selectedCompany.CompCode) {
      setError('Company code is missing or invalid');
      return;
    }

    const queryParams = new URLSearchParams({
      name: salesmanName.trim().toLowerCase(),
      CompCode: selectedCompany.CompCode,
    });

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/salesman-suggestions?${queryParams.toString()}`, {
        headers: {
          'UserId': userId,
          'Authorization': `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) throw new Error('Network response was not ok');

      const data = await response.json();
      setSalesmanSuggestions(data);
    } catch (error) {
      console.error('Error fetching salesman suggestions:', error);
      setError(error.message);
    }
  }, [salesmanName]);

  const fetchAreaSuggestions = useCallback(async () => {
    const userId = localStorage.getItem('userId');
    const accessToken = localStorage.getItem('accessToken');

    if (!areaWiseValue || !areaWiseValue.trim()) {
      setAreaSuggestions([]);
      return;
    }

    const selectedCompany = JSON.parse(localStorage.getItem('selectedCompany'));
    if (!selectedCompany || !selectedCompany.CompCode) {
      setError('Company code is missing or invalid');
      return;
    }

    const queryParams = new URLSearchParams({
      area: areaWiseValue.trim(),
      CompCode: selectedCompany.CompCode,
    });


    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/area-suggestions?${queryParams.toString()}`, {
        headers: {
          'UserId': userId,
          'Authorization': `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) throw new Error('Network response was not ok');

      const data = await response.json();
      setAreaSuggestions(data);
      console.log(data)
    } catch (error) {
      console.error('Error fetching area suggestions:', error);
      setError(error.message);
    }
  }, [areaWiseValue]);

  const fetchRootSuggestions = useCallback(async () => {
    const userId = localStorage.getItem('userId');
    const accessToken = localStorage.getItem('accessToken');

    if (!rootWiseValue.trim()) {
      setRootSuggestions([]);
      return;
    }

    const selectedCompany = JSON.parse(localStorage.getItem('selectedCompany'));
    if (!selectedCompany || !selectedCompany.CompCode) {
      setError('Company code is missing or invalid');
      return;
    }

    const queryParams = new URLSearchParams({
      root: rootWiseValue.trim(),
      CompCode: selectedCompany.CompCode,
    });

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/root-suggestions?${queryParams.toString()}`, {
        headers: {
          'UserId': userId,
          'Authorization': `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) throw new Error('Network response was not ok');

      const data = await response.json();
      setRootSuggestions(data);
      console.log(data)
    } catch (error) {
      console.error('Error fetching root suggestions:', error);
      setError(error.message);
    }
  }, [rootWiseValue]);



  const fetchCombinedRootSuggestions = useCallback(async () => {
    const userId = localStorage.getItem('userId');
    const accessToken = localStorage.getItem('accessToken');

    if (!combinedRootValue.trim()) {
      setCombinedRootSuggestions([]);
      return;
    }

    const selectedCompany = JSON.parse(localStorage.getItem('selectedCompany'));
    if (!selectedCompany || !selectedCompany.CompCode) {
      setError('Company code is missing or invalid');
      return;
    }

    const queryParams = new URLSearchParams({
      root: combinedRootValue.trim(),
      CompCode: selectedCompany.CompCode,
    });

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/root-suggestions?${queryParams.toString()}`, {
        headers: {
          'UserId': userId,
          'Authorization': `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) throw new Error('Network response was not ok');

      const data = await response.json();
      setCombinedRootSuggestions(data);
    } catch (error) {
      console.error('Error fetching combined root suggestions:', error);
      setError(error.message);
    }
  }, [combinedRootValue]);

  const fetchCombinedSalesmanSuggestions = useCallback(async () => {
    const userId = localStorage.getItem('userId');
    const accessToken = localStorage.getItem('accessToken');

    if (!combinedSalesmanValue.trim()) {
      setCombinedSalesmanSuggestions([]);
      return;
    }

    const selectedCompany = JSON.parse(localStorage.getItem('selectedCompany'));
    if (!selectedCompany || !selectedCompany.CompCode) {
      setError('Company code is missing or invalid');
      return;
    }

    const queryParams = new URLSearchParams({
      name: combinedSalesmanValue.trim().toLowerCase(),
      CompCode: selectedCompany.CompCode,
    });

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/salesman-suggestions?${queryParams.toString()}`, {
        headers: {
          'UserId': userId,
          'Authorization': `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) throw new Error('Network response was not ok');

      const data = await response.json();
      setCombinedSalesmanSuggestions(data);
    } catch (error) {
      console.error('Error fetching combined salesman suggestions:', error);
      setError(error.message);
    }
  }, [combinedSalesmanValue]);

  useEffect(() => {
    if(skip.includes("combinerootname")){
      return
    }
    fetchCombinedRootSuggestions();
  }, [combinedRootValue, fetchCombinedRootSuggestions,skip]);

  useEffect(() => {
    if(skip.includes("combinesalesman")){
      return
    }
    fetchCombinedSalesmanSuggestions();
  }, [combinedSalesmanValue, fetchCombinedSalesmanSuggestions,skip]);

  useEffect(() => {
    if (skip.includes("partySuggestions")) {
      return
    }
    fetchPartySuggestions();
  }, [partyWiseValue, fetchPartySuggestions, skip]);

  useEffect(() => {
    if(skip.includes("salesmanSuggestions")) {
      return
    }
    fetchSalesmanSuggestions();
  }, [salesmanName, fetchSalesmanSuggestions,skip]);

  useEffect(() => {
    if(skip.includes("areaNameSuggestion")){
      return
    }
    fetchAreaSuggestions();
  }, [areaWiseValue, fetchAreaSuggestions,skip]);

  useEffect(() => {
    if(skip.includes("RootNameSuggestion")){
      return
    }
    fetchRootSuggestions();
  }, [rootWiseValue, fetchRootSuggestions,skip]);

  useEffect(() => {
    if(skip.includes("subagentSuggestion")){
      return
    }
    fetchSubAgentSuggestions();
  }, [subAgentValue, fetchSubAgentSuggestions,skip]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const selectedCompany = JSON.parse(localStorage.getItem('selectedCompany'));
    if (!selectedCompany || !selectedCompany.CompCode) {
      setError('Company code is missing or invalid');
      return;
    }

    const userId = localStorage.getItem('userId');
    const accessToken = localStorage.getItem('accessToken');
    const selectedSalesmanCode = localStorage.getItem('selectedSalesmanCode');
    const selectedPartyCode = localStorage.getItem('selectedPartyCode');
    const selectedAreaCode = localStorage.getItem('selectedAreaCode');
    const selectedRootCode = localStorage.getItem('selectedRootCode');


    const queryParams = new URLSearchParams({
      type: type || '',
      dueDays: dueDays || '0',
      criteria: criteria || '',
      operator: operator || '',
      date: date || '',
      CompCode: selectedCompany.CompCode,
    });

    if (criteria === 'partywise') {
      queryParams.append('partyCode', selectedPartyCode || '');
    } else if (criteria === 'salesman') {
      queryParams.append('salesmanCode', selectedSalesmanCode || '');
    } else if (criteria === 'areawise') {
      queryParams.append('areaCode', selectedAreaCode || '');
    } else if (criteria === 'rootwise') {
      queryParams.append('rootCode', selectedRootCode || '');
    } else if (criteria === 'root-salesman') {
      queryParams.append('rootCode', localStorage.getItem('selectedRootCode') || '');
      queryParams.append('salesmanCode', localStorage.getItem('selectedSalesmanCode') || '');
    } else if (criteria === 'subagentwise')
      queryParams.append('subAgentCode', localStorage.getItem('selectedSubAgentCode') || '');

    const apiUrl = type === 'Receivable'
      ? `${process.env.REACT_APP_API_URL}/outstanding-receivables?${queryParams.toString()}`
      : `${process.env.REACT_APP_API_URL}/outstanding-payables?${queryParams.toString()}`;

    try {
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'UserId': userId || '',
          'Authorization': `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) throw new Error('Network response was not ok');

      navigate(`/dataTable?${queryParams.toString()}`);
    } catch (error) {
      console.error('Error fetching outstanding data:', error);
      setError(error.message);
    }
  };

  return (
    <div>
      <div>
      <Header/>
      </div>
    <div>
      <Sidebar />
      <div className="outstanding-form">
        <form onSubmit={handleSubmit}>
          <h1>Outstanding</h1>

          <div className="radio-group">
            <label>
              <input
                type="radio"
                name="type"
                value="Receivable"
                checked={type === 'Receivable'}
                onChange={handleChange(setType)}
              />
               Receivable
            </label>
            <label>
              <input
                type="radio"
                name="type"
                value="Payables"
                checked={type === 'Payables'}
                onChange={handleChange(setType)}
              />
               Payables
            </label>
          </div>

          <div>
            <label>Select Date:</label>
            <input
              type="date"
              value={date}
              onChange={handleChange(setDate)}
            />
          </div>

          <div>
            <label>Select Criteria:</label>
            <select
              value={criteria}
              onChange={handleChange(setCriteria)}
            >
              <option value="datewise">DateWise</option>
              <option value="partywise">PartyWise</option>
              <option value="salesman">Salesman</option>
              <option value="subagentwise">SubAgentWise</option>
              <option value="rootwise">RootWise</option>
              <option value="areawise">AreaWise</option>
              <option value="root-salesman">Root + SalesmanWise</option>
            </select>
          </div>

          <div>
            <label>Select Operator:</label>
            <select
              value={operator}
              onChange={handleChange(setOperator)}
            >
              <option value="">-- Select an operator --</option>
              <option value="=">=</option>
              <option value="<">&lt;</option>
              <option value=">">&gt;</option>
              <option value="<=">&le;</option>
              <option value=">=">&ge;</option>
              <option value="<>">&ne;</option>
            </select>
          </div>

          <div>
            <label>Due Days</label>
            <input
              type="number"
              value={dueDays}
              onChange={handleChange(setDueDays)}
            />
          </div>

          {criteria === 'partywise' && (
            <div className="partywise-container">
              <label>PartyWise</label>
              <input
                type="text"
                value={partyWiseValue}
                onChange={handleChange(setPartyWiseValue)}
                placeholder="Enter Party Name"
              />
              {partySuggestions.length > 0 && (
                <ul className="suggestions-list">
                  {partySuggestions.map((suggestion) => (
                    <li key={`party-${suggestion.code}`} onClick={() => {
                      setPartyWiseValue(suggestion.partyName);
                      setSkip(prev => [...prev,"partySuggestions"])
                      localStorage.setItem('selectedPartyCode', suggestion.code);
                      setPartySuggestions([]);
                    }}>
                      {suggestion.partyName}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )}

          {criteria === 'salesman' && (
            <div>
              <label>Salesman Name</label>
              <input
                type="text"
                value={salesmanName}
                onChange={handleChange(setSalesmanName)}
                placeholder="Enter Salesman Name"
              />
              {salesmanSuggestions.length > 0 && (
                <ul className="suggestions-list2">
                  {salesmanSuggestions.map((suggestion) => (
                    <li key={`salesman-${suggestion.Code}`} onClick={() => {
                      setSalesmanName(suggestion.Name);
                      setSkip(prev => [...prev,"salesmanSuggestions"])
                      localStorage.setItem('selectedSalesmanCode', suggestion.Code);
                      setSalesmanSuggestions([]);
                    }}>
                      {suggestion.Name}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )}

          {criteria === 'areawise' && (
            <div>
              <label>Area Name</label>
              <input
                type="text"
                value={areaWiseValue}
                onChange={handleChange(setAreaWiseValue)}
                placeholder="Enter Area Name"
              />
              {areaSuggestions.length > 0 && (
                <ul className="suggestions-list2">
                  {areaSuggestions.map((suggestion, index) => (
                    <li key={`area-${suggestion.serialno || index}`} onClick={() => {
                      setAreaWiseValue(suggestion.Area);
                      localStorage.setItem('selectedAreaCode', suggestion.SerialNo);
                      setSkip(prev => [...prev,"areaNameSuggestion"])
                      setAreaSuggestions([]);
                    }}>
                      {suggestion.Area}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )}
          {criteria === 'rootwise' && (
            <div>
              <label>Root Name</label>
              <input
                type="text"
                value={rootWiseValue}
                onChange={handleChange(setRootWiseValue)}
                placeholder="Enter Root Name"
              />
              {rootSuggestions.length > 0 && (
                <ul className="suggestions-list2">
                  {rootSuggestions.map((suggestion, index) => (
                    <li key={`root-${suggestion.srno || index}`} onClick={() => {
                      setRootWiseValue(suggestion.Root);
                      localStorage.setItem('selectedRootCode', suggestion.SrNo);
                      setSkip(prev => [...prev,"RootNameSuggestion"])
                      setRootSuggestions([]);
                    }}>
                      {suggestion.Root}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )}

          {criteria === 'root-salesman' && (
            <div>
              <label>Root Name</label>
              <input
                type="text"
                value={combinedRootValue}
                onChange={handleChange(setCombinedRootValue)}
                placeholder="Enter Root Name"
              />
              {combinedRootSuggestions.length > 0 && (
                <ul className="suggestions-list2">
                  {combinedRootSuggestions.map((suggestion, index) => (
                    <li key={`combined-root-${suggestion.srno || index}`} onClick={() => {
                      setCombinedRootValue(suggestion.Root);
                      setSkip(prev => [...prev,"combinerootname"])
                      localStorage.setItem('selectedRootCode', suggestion.SrNo);
                      setCombinedRootSuggestions([]);
                    }}>
                      {suggestion.Root}
                    </li>
                  ))}
                </ul>
              )}

              <label>Salesman Name</label>
              <input
                type="text"
                value={combinedSalesmanValue}
                onChange={handleChange(setCombinedSalesmanValue)}
                placeholder="Enter Salesman Name"
              />
              {combinedSalesmanSuggestions.length > 0 && (
                <ul className="suggestions-list2">
                  {combinedSalesmanSuggestions.map((suggestion) => (
                    <li key={`combined-salesman-${suggestion.Code}`} onClick={() => {
                      setCombinedSalesmanValue(suggestion.Name);
                      setSkip(prev => [...prev,"combinesalesman"])
                      localStorage.setItem('selectedSalesmanCode', suggestion.Code);
                      setCombinedSalesmanSuggestions([]);
                    }}>
                      {suggestion.Name}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )}

          {criteria === 'subagentwise' && (
            <div>
              <label>Sub-Agent Name</label>
              <input
                type="text"
                value={subAgentValue}
                onChange={handleChange(setSubAgentValue)}
                placeholder="Enter Sub-Agent Name"
              />
              {subAgentSuggestions.length > 0 && (
                <ul className="suggestions-list2">
                  {subAgentSuggestions.map((suggestion) => (
                    <li key={`subagent-${suggestion.Code}`} onClick={() => {
                      setSubAgentValue(suggestion.Name);
                      setSkip(prev => [...prev,"subagentSuggestion"])
                      localStorage.setItem('selectedSubAgentCode', suggestion.Code);
                      setSubAgentSuggestions([]);
                    }}>
                      {suggestion.Name}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )}


          <div>
            <button type="submit">Generate</button>
          </div>
        </form>

        {error && <p className="error-message">Error: {error}</p>}
      </div>
    </div>
      </div>
  );
}

export default Outstanding;
