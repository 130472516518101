import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './css/DataTable.css';
import Header from './Dashboard/Header.js';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx'; // Import the xlsx library

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

const DataTable = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [totalAmount, setTotalAmount] = useState(0);
  const [expandedRows, setExpandedRows] = useState({});
  const [expandedBill, setExpandedBill] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: 'partyName', direction: 'ascending' });
  const [showDropdown, setShowDropdown] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 11;

  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm]);

  const fetchData = useCallback(async () => {
    const queryParams = new URLSearchParams(location.search);
    const date = queryParams.get('date');
    const compCode = queryParams.get('CompCode');
    const dueDays = queryParams.get('dueDays');
    const type = queryParams.get('type') || 'Receivable';
    const partyCode = queryParams.get('partyCode');
    const rootCode = queryParams.get('rootCode');
    const areaCode = queryParams.get('areaCode');
    const salesmanCode = queryParams.get('salesmanCode');
    const subAgentCode = queryParams.get('subAgentCode');

    if (!date || !compCode || !dueDays) return;

    const endpoint = type === 'Receivable' ? 'outstanding-receivables' : 'outstanding-payables';
    const url = `${process.env.REACT_APP_API_URL}/${endpoint}?date=${date}&CompCode=${compCode}&dueDays=${dueDays}`;

    setLoading(true);
    setError(null);

    try {
      const userId = localStorage.getItem('userId');
      const accessToken = localStorage.getItem('accessToken');
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'UserId': userId || '',
          'Authorization': `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();

      if (Array.isArray(result)) {
        const filteredResult = result.filter(item => {
          const matchesPartyCode = !partyCode || item.field6 === partyCode;
          const matchesAreaCode = !areaCode || item.field21 === areaCode;
          const matchesRootCode = !rootCode || item.field23 === rootCode;
          const matchesSalesmanCode = !salesmanCode || item.field15 === salesmanCode;
          const matchesSubAgentCode = !subAgentCode || item.field15 === subAgentCode;
          const matchesRootSalesman = (!rootCode && !salesmanCode) || (matchesRootCode && matchesSalesmanCode);

          return (
            matchesPartyCode &&
            matchesAreaCode &&
            matchesRootCode &&
            matchesSalesmanCode &&
            matchesSubAgentCode &&
            matchesRootSalesman
          );
        });

        const formattedData = filteredResult.map(item => ({
          ...item,
          field4: formatDate(item.field4),
          field12: formatDate(item.field12),
        }));

        setData(formattedData);
        const total = formattedData.reduce((acc, item) => acc + (parseFloat(item.field11) || 0), 0);
        setTotalAmount(total);
      } else {
        throw new Error('Unexpected data format');
      }
    } catch (error) {
      console.error("Fetch error:", error);
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [location]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (loading) return <div className="loading">Loading...</div>;
  if (error) return <div className="error">Error: {error.message}</div>;

  const groupedData = data.reduce((acc, item) => {
    const partyName = item.field5;
    if (!acc[partyName]) {
      acc[partyName] = [];
    }
    acc[partyName].push(item);
    return acc;
  }, {});

  const type = new URLSearchParams(location.search).get('type');
  const heading = type === 'Receivable' ? 'Outstanding Receivables' : 'Outstanding Payables';
  const selectedDate = new URLSearchParams(location.search).get('date') ? formatDate(new URLSearchParams(location.search).get('date')) : 'N/A';

  const handleToggle = (partyName) => {
    setExpandedRows(prev => ({
      ...prev,
      [partyName]: !prev[partyName],
    }));
  };

  const sortedData = Object.keys(groupedData).sort((a, b) => {
    const totalA = groupedData[a].reduce((acc, item) => acc + (parseFloat(item.field11) || 0), 0);
    const totalB = groupedData[b].reduce((acc, item) => acc + (parseFloat(item.field11) || 0), 0);

    if (sortConfig.key === 'partyName') {
      return sortConfig.direction === 'ascending' ? a.localeCompare(b) : b.localeCompare(a);
    } else if (sortConfig.key === 'totalAmount') {
      return sortConfig.direction === 'ascending' ? totalA - totalB : totalB - totalA;
    }
    return 0;
  });

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const generatePDF = () => {
    const doc = new jsPDF();
    doc.setFontSize(16);
    doc.setFont('helvetica', 'bold');
    const companyName = localStorage.getItem('companyName') || "Default Company Name";
    doc.text(companyName, 105, 20, { align: 'center' });
    doc.setFontSize(11);

    let currentY = 30;

    const headers = ["Bill Number", "Bill Date", "Bill Amt", "Receive Amt", "Return Amt", "Balance Amt"];
    const dataHeaderStyles = { fillColor: [70, 130, 180], textColor: [255, 255, 255], fontSize: 12 };

    for (const partyName of Object.keys(groupedData)) {
      const partyEntries = groupedData[partyName];

      if (currentY + 10 + (partyEntries.length * 10) + 10 > doc.internal.pageSize.height) {
        doc.addPage();
        currentY = 10;
      }

      doc.autoTable({
        head: [[partyName]],
        startY: currentY,
        theme: 'grid',
        headStyles: { textColor: [0, 0, 0], fontSize: 12 },
        styles: { fillColor: [240, 240, 240], textColor: [0, 0, 0] },
      });

      currentY = doc.lastAutoTable.finalY;

      const pdfData = partyEntries.map(row => [
        row.field3, row.field4, row.field7, row.field10, row.field8, row.field11,
      ]);

      doc.autoTable({
        head: [headers],
        body: pdfData,
        startY: currentY,
        theme: 'grid',
        headStyles: dataHeaderStyles,
      });

      currentY = doc.lastAutoTable.finalY + 5;

      const totalBalance = partyEntries.reduce((acc, row) => acc + parseFloat(row.field11 || 0), 0);
      const totalText = `Total Balance: ${totalBalance.toFixed(2)}`;
      doc.setFont('helvetica', 'bold');
      doc.text(totalText, 145, currentY);
      currentY += 10;
    }

    doc.save(`${heading}.pdf`);
  };

  const generateExcel = () => {
    const exportData = [];

    // Add Heading (Column Names)
    exportData.push([
      'Party Name', 'Bill Number', 'Bill Date', 'Bill Amt',
      'Receive Amt', 'Return Amt', 'Balance Amt'
    ]);

    let totalBalanceAmt = 0;  // Global total of balance amount

    // Loop through the groupedData and push each entry into exportData
    for (const partyName of Object.keys(groupedData)) {
      const partyEntries = groupedData[partyName];

      // Start the party's data with the party name
      exportData.push([partyName]);  // Party Name row

      let partyBalanceAmt = 0;  // Party-specific balance total

      partyEntries.forEach(entry => {
        const billAmt = entry.field7 || 0;
        const receiveAmt = entry.field10 || 0;
        const returnAmt = entry.field8 || 0;

        // Ensure balanceAmt is a valid number
        const balanceAmt = isNaN(entry.field11) ? 0 : parseFloat(entry.field11);

        // Update total balance for the current party
        partyBalanceAmt += balanceAmt;

        exportData.push([
          entry.field3, // Bill Number
          entry.field4, // Bill Date
          billAmt,       // Bill Amt
          receiveAmt,    // Receive Amt
          returnAmt,     // Return Amt
          balanceAmt.toFixed(2),    // Balance Amt (formatted to 2 decimal places)
        ]);
      });

      // Add total row for this party (only for Balance Amt)
      exportData.push([
        'Total', '', '', '', '', partyBalanceAmt.toFixed(2)  // Rounded party total
      ]);

      // Update global balance total
      totalBalanceAmt += partyBalanceAmt;
    }

    // Add a final total row at the bottom (total of all balance amounts)
    exportData.push([
      'Grand Total', '', '', '', '', totalBalanceAmt.toFixed(2)  // Rounded grand total
    ]);

    // Create the worksheet and workbook
    const ws = XLSX.utils.aoa_to_sheet(exportData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Outstanding Data');

    // Now explicitly set the numbers to be numeric in Excel
    const range = XLSX.utils.decode_range(ws['!ref']);
    for (let row = range.s.r; row <= range.e.r; row++) {
      for (let col = range.s.c; col <= range.e.c; col++) {
        const cell = ws[XLSX.utils.encode_cell({ r: row, c: col })];
        if (cell && !isNaN(cell.v)) {
          // Ensure cells that look like numbers are treated as numbers
          cell.t = 'n';  // 'n' for numbers in Excel
        }
      }
    }

    // Generate Excel file and trigger download
    XLSX.writeFile(wb, `${heading}.xlsx`);
  };




  const toggleDropdown = () => setShowDropdown(prevState => !prevState);



  return (
    <div className="data-table-container">
      <div>
        <Header />
      </div>
      <div className="header-container">
        <button className="back-button" onClick={() => navigate(-1)}>Back</button>
      </div>
      <div className="amountHeading">
        <h1>
          {heading} <br /><span>As on Date: {selectedDate}</span>
          <input
            type="text"
            className="search-header"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <br /><br />
          <span>Total Outstanding Amount Rs:</span>
          <span className="total-amount">{totalAmount.toFixed(2)}</span>

          <div className="pdfContainer">
            <button className="generate-button" onClick={toggleDropdown}>
              Generate File
              {showDropdown && (
                <div className="dropdown-content">
                  <button onClick={generatePDF}>PDF</button>
                  <button onClick={generateExcel}>Excel</button>
                </div>
              )}
            </button>
          </div>
        </h1><br />
      </div>
      <div id="data-table-to-pdf">
        <div className="party-details">
          <div className="party-summary-header">
            <div className="sortable-header" onClick={() => handleSort('partyName')}>
              Party Name
              {sortConfig.key === 'partyName' ? (sortConfig.direction === 'ascending' ? ' ▲' : ' ▼') : ' ↕️'}
            </div>
            <div className="sortable-header" onClick={() => handleSort('totalAmount')}>
              Total Amount
              {sortConfig.key === 'totalAmount' ? (sortConfig.direction === 'ascending' ? ' ▲' : ' ▼') : ' ↕️'}
            </div>
          </div>

          {sortedData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((partyName, index) => {
            const partyTotal = groupedData[partyName].reduce((acc, item) => acc + (parseFloat(item.field11) || 0), 0);
            const isExpanded = !!expandedRows[partyName];

            return (
              <div key={index} className="party-info">
                <div className="party-summary" onClick={() => handleToggle(partyName)}>
                  <span className="toggle-sign">{isExpanded ? '−' : '+'}</span>
                  <span className="party-name">{partyName}</span>
                  <span className={`total-amt ${partyTotal < 0 ? 'negative-amount' : ''}`}>
                    {partyTotal.toFixed(2)}
                  </span>
                </div>

                {isExpanded && (
                  <table className="data-table">
                    <thead>
                      <tr>
                        <th>Bill Number</th>
                        <th>Bill Date</th>
                        <th>Bill Amt</th>
                        <th>Balance Amt</th>
                        <th>Due Days</th>
                      </tr>
                    </thead>
                    <tbody>
                      {groupedData[partyName].map((row, rowIndex) => (
                        <React.Fragment key={rowIndex}>
                          <tr>
                            <td>
                              <button onClick={() => setExpandedBill(expandedBill === row.field3 ? null : row.field3)} className="bill-toggle">
                                {expandedBill === row.field3 ? '−' : '+'}
                              </button>
                              {row.field3}
                            </td>
                            <td>{row.field4}</td>
                            <td className={parseFloat(row.field7) < 0 ? 'negative-amount' : ''}>{row.field7}</td>
                            <td className={parseFloat(row.field11) < 0 ? 'negative-amount' : ''}>{row.field11}</td>
                            <td>{row.field18}</td>
                          </tr>
                          {expandedBill === row.field3 && (
                            <tr>
                              <td colSpan="5">
                                <div className="details-box">
                                  <div className="details-item">
                                    <strong>Bill Amt:</strong> {row.field7}
                                  </div>
                                  <div className="details-item">
                                    <strong>Receive Amt:</strong> {row.field10}
                                  </div>
                                  <div className="details-item">
                                    <strong>Return Amt:</strong> {row.field8}
                                  </div>
                                  <div className="details-item">
                                    <strong>Balance Amt:</strong> {row.field11}
                                  </div>
                                </div>
                              </td>
                            </tr>
                          )}
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            );
          })}
        </div>

        {Object.keys(groupedData).length === 0 && (
          <div className="no-data">No data available</div>
        )}

        {/* Pagination Controls */}
        <div className="pagination-controls">
          <button
            className="pagination-button"
            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
          >
            &#8592;
          </button>
          <span>Page {currentPage} of {Math.ceil(Object.keys(groupedData).length / itemsPerPage)}</span>
          <button
            className="pagination-button"
            onClick={() => setCurrentPage(prev => Math.min(prev + 1, Math.ceil(Object.keys(groupedData).length / itemsPerPage)))}
            disabled={currentPage === Math.ceil(Object.keys(groupedData).length / itemsPerPage)}
          >
            &#8594;
          </button>
        </div>
      </div>
    </div>
  );
};

export default DataTable;
