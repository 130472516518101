import React, { useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import Sidebar from './SideBar.js';
import Card from '../Dashboard/Card1.js';
import '../css/DashboardPage.css';
import axios from 'axios';
import { Pie, Bar } from 'react-chartjs-2';
import SalesChart from './SalesChart.js';
import { Chart, ArcElement, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';
import PurchaseChart from './PurchaseChart.js';
import PaymentChart from './PaymentChart.js';
import ReceiptChart from './RecepitChart.js';



Chart.register(ArcElement, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

const DashboardPage = () => {
  const location = useLocation();
  const [companyName, setCompanyName] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [totalReceivables, setTotalReceivables] = useState(0);
  const [totalPayables, setTotalPayables] = useState(0);
  const [currentTotalCash, setCurrentTotalCash] = useState(0);
  const [currentTotalCredit, setCurrentTotalCredit] = useState(0);
  const [currentLocalAmount, setCurrentLocalAmount] = useState(0);
  const [currentOutsideAmount, setCurrentOutsideAmount] = useState(0);
  const [purchaseData, setPurchaseData] = useState([]);
  const [currentCard, setCurrentCard] = useState([]);
  const [currentWallet, setCurrentWallet] = useState([]);
  const [currentCreditNote, setCurrentCreditNote] = useState([]);
  const [prevTotalReceivables, setPrevTotalReceivables] = useState(() => parseFloat(localStorage.getItem('prevTotalReceivables')) || 0);
  const [prevTotalPayables, setPrevTotalPayables] = useState(() => parseFloat(localStorage.getItem('prevTotalPayables')) || 0);
  const [prevTotalCash, setPrevTotalCash] = useState(() => parseFloat(localStorage.getItem('prevTotalCash')) || 0);
  const [prevTotalCredit, setPrevTotalCredit] = useState(() => parseFloat(localStorage.getItem('prevTotalCredit')) || 0);
  const [prevLocalAmountStored, setPrevLocalAmountStored] = useState(() => parseFloat(localStorage.getItem('prevLocalAmount')) || 0);
  const [prevOutsideAmountStored, setPrevOutsideAmountStored] = useState(() => parseFloat(localStorage.getItem('prevOutsideAmount')) || 0);
  const [previousCard, setPreviousCard] = useState(() => parseFloat(localStorage.getItem('previousCard')) || 0)
  const [previousWallet, setPreviousWallet] = useState(() => parseFloat(localStorage.getItem('previousWallet')) || 0)
  const [previousCreditNote, setPreviousCreditNote] = useState(() => parseFloat(localStorage.getItem('previousCreditNote')) || 0)


  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const urlCompanyName = queryParams.get('companyName');
    const urlFromDate = queryParams.get('fromDate');
    const urlToDate = queryParams.get('toDate');

    if (urlCompanyName && urlFromDate && urlToDate) {
      localStorage.setItem('companyName', urlCompanyName);
      localStorage.setItem('fromDate', urlFromDate);
      localStorage.setItem('toDate', urlToDate);
      setCompanyName(urlCompanyName);
      setFromDate(urlFromDate);
      setToDate(urlToDate);
    } else {
      setCompanyName(localStorage.getItem('companyName') || '');
      setFromDate(localStorage.getItem('fromDate') || '');
      setToDate(localStorage.getItem('toDate') || '');
    }
  }, [location.search]);

  const fetchOutstandingData = useCallback(async (endpoint, setTotal, setPrevTotal) => {
    try {
      const today = new Date().toISOString().split('T')[0];
      const selectedCompany = JSON.parse(localStorage.getItem('selectedCompany'));
      const compCode = selectedCompany ? selectedCompany.CompCode : '';

      const response = await axios.get(`${process.env.REACT_APP_API_URL}/${endpoint}`, {
        params: { date: today, CompCode: compCode, dueDays: 0 },
        headers: {
          'UserId': localStorage.getItem('userId'),
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        }
      });

      const total = response.data.reduce((acc, item) => acc + (parseFloat(item.field11) || 0), 0);

      if (setPrevTotal === setPrevTotalReceivables && prevTotalReceivables === 0) {
        localStorage.setItem('prevTotalReceivables', total);
        setPrevTotalReceivables(total);
      } else if (setPrevTotal === setPrevTotalPayables && prevTotalPayables === 0) {
        localStorage.setItem('prevTotalPayables', total);
        setPrevTotalPayables(total);
      }

      setTotal(total.toFixed(2));
    } catch (error) {
      console.error(`Error fetching ${endpoint}:`, error);
    }
  }, [prevTotalReceivables, prevTotalPayables]);

  const fetchPaymentData = useCallback(async () => {
    try {
      const selectedCompany = JSON.parse(localStorage.getItem('selectedCompany'));
      const compCode = selectedCompany ? selectedCompany.CompCode : '';

      const response = await axios.get(`${process.env.REACT_APP_API_URL}/get-summary`, {
        params: { CompCode: compCode },
        headers: {
          'UserId': localStorage.getItem('userId'),
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        }
      });

      if (Array.isArray(response.data) && response.data.length > 0) {
        const cashTotal = response.data
          .filter(item => item.PAYMENT === 'CASH')
          .reduce((acc, item) => acc + (parseFloat(item.Amount) || 0), 0);

        const creditTotal = response.data
          .filter(item => item.PAYMENT === 'CREDIT')
          .reduce((acc, item) => acc + (parseFloat(item.Amount) || 0), 0);

        const cardTotal = response.data
          .filter(item => item.PAYMENT === 'CARD')
          .reduce((acc, item) => acc + (parseFloat(item.Amount) || 0), 0);

        const walletTotal = response.data
          .filter(item => item.PAYMENT === 'WALLET')
          .reduce((acc, item) => acc + (parseFloat(item.Amount) || 0), 0);

        const creditNoteTotal = response.data
          .filter(item => item.PAYMENT === 'CREDIT NOTE')
          .reduce((acc, item) => acc + (parseFloat(item.Amount) || 0), 0);

        if (prevTotalCash === 0) {
          localStorage.setItem('prevTotalCash', currentTotalCash);
          setPrevTotalCash(currentTotalCash);
        }

        if (prevTotalCredit === 0) {
          localStorage.setItem('prevTotalCredit', currentTotalCredit);
          setPrevTotalCredit(currentTotalCredit);
        }

        if (prevLocalAmountStored === 0) {
          localStorage.setItem('prevLocalAmount', currentLocalAmount);
          setPrevLocalAmountStored(currentLocalAmount);
        }

        if (prevOutsideAmountStored === 0) {
          localStorage.setItem('prevOutsideAmount', currentOutsideAmount);
          setPrevOutsideAmountStored(currentOutsideAmount);
        }

        if (previousCard === 0) {
          localStorage.setItem('previousCard', cardTotal);
          setPreviousCard(cardTotal);
        }

        if (previousWallet === 0) {
          localStorage.setItem('previousWallet', walletTotal);
          setPreviousWallet(walletTotal);
        }

        if (previousCreditNote === 0) {
          localStorage.setItem('previousCreditNote', creditNoteTotal);
          setPreviousCreditNote(creditNoteTotal);
        }

        setCurrentTotalCash(cashTotal);
        setCurrentTotalCredit(creditTotal);
        setCurrentCard(cardTotal);
        setCurrentWallet(walletTotal);
        setCurrentCreditNote(creditNoteTotal);
      } else {
        console.warn('No payment data received or data is not in expected format');
        setCurrentTotalCash(0);
        setCurrentTotalCredit(0);
        setCurrentCard(0);
        setCurrentWallet(0);
        setCurrentCreditNote(0);
      }
    } catch (error) {
      console.error('Error fetching payment data:', error);
    }
  }, [currentTotalCash, currentTotalCredit, currentLocalAmount, currentOutsideAmount, prevLocalAmountStored, prevOutsideAmountStored, prevTotalCash, prevTotalCredit,
    previousCard, previousWallet, previousCreditNote]);

  const fetchPurchaseSummary = useCallback(async () => {
    try {
      const selectedCompany = JSON.parse(localStorage.getItem('selectedCompany'));
      const compCode = selectedCompany ? selectedCompany.CompCode : '';

      const response = await axios.get(`${process.env.REACT_APP_API_URL}/purchase-summary`, {
        params: { CompCode: compCode },
        headers: {
          'UserId': localStorage.getItem('userId'),
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        }
      });

      if (Array.isArray(response.data) && response.data.length > 0) {
        setPurchaseData(response.data);
      } else {
        console.warn('No purchase data received or data is not in expected format');
        setPurchaseData([]);
      }
    } catch (error) {
      console.error('Error fetching purchase summary:', error);
    }
  }, []);

  useEffect(() => {
    if (companyName && fromDate && toDate) {
      fetchOutstandingData('outstanding-receivables', setTotalReceivables, setPrevTotalReceivables);
      fetchOutstandingData('outstanding-payables', setTotalPayables, setPrevTotalPayables);
      fetchPaymentData();
      fetchPurchaseSummary();
    }
  }, [companyName, fromDate, toDate, fetchPaymentData, fetchOutstandingData, fetchPurchaseSummary]);

  useEffect(() => {
    const localAmount = purchaseData.find(item => item.sptype === 'LOCAL')?.Amount || 0;
    const outsideAmount = purchaseData.find(item => item.sptype === 'OUTSIDE')?.Amount || 0;

    setCurrentLocalAmount(localAmount);
    setCurrentOutsideAmount(outsideAmount);
  }, [purchaseData]);

  const calculatePercentageChange = (change, prevValue) => {
    if (prevValue === 0) return 0; // Avoid division by zero
    return ((change / prevValue) * 100).toFixed(2); // Calculate percentage change
  };

  // Calculate changes
  const cashChange = currentTotalCash - prevTotalCash;
  const creditChange = currentTotalCredit - prevTotalCredit;
  const localChange = currentLocalAmount - prevLocalAmountStored;
  const outsideChange = currentOutsideAmount - prevOutsideAmountStored;
  const receivablesChange = totalReceivables - prevTotalReceivables;
  const payablesChange = totalPayables - prevTotalPayables;
  const cardChange = currentCard - previousCard;
  const walletChange = currentWallet - previousWallet;
  const creditNoteChange = currentCreditNote - previousCreditNote;

  const localPercentageChange = calculatePercentageChange(localChange, prevLocalAmountStored);
  const outsidePercentageChange = calculatePercentageChange(outsideChange, prevOutsideAmountStored);
  const receivablesPercentageChange = calculatePercentageChange(receivablesChange, prevTotalReceivables);
  const payablesPercentageChange = calculatePercentageChange(payablesChange, prevTotalPayables);
  const cashPercentageChange = calculatePercentageChange(cashChange, prevTotalCash);
  const creditPercentageChange = calculatePercentageChange(creditChange, prevTotalCredit);
  const cardPercentageChange = calculatePercentageChange(cardChange, previousCard);
  const walletPercentageChange = calculatePercentageChange(walletChange, previousWallet);
  const creditNotePercentageChange = calculatePercentageChange(creditNoteChange, previousCreditNote);

  const pieData = {
    labels: ['LOCAL', 'OUTSIDE'],
    datasets: [{
      data: [currentLocalAmount, currentOutsideAmount],
      backgroundColor: ['#4BC0C0', '#FF9F40'],
      hoverBackgroundColor: ['#4BC0C0', '#FF9F40']
    }]
  };

  const paymentTypes = [
    { type: 'CASH', amount: currentTotalCash, color: '#FF6384' },
    { type: 'CREDIT', amount: currentTotalCredit, color: '#36A2EB' },
    { type: 'CARD', amount: currentCard, color: '#FFCE56' },
    { type: 'WALLET', amount: currentWallet, color: '#4BC0C0' },
    { type: 'CR-NOTE', amount: currentCreditNote, color: '#9966FF' }
  ];

  // Filter out types with a value of 0
  const filteredPaymentTypes = paymentTypes.filter(item => item.amount > 0);

  // Prepare the labels, data, and colors for the chart
  const labels = filteredPaymentTypes.map(item => item.type);
  const data = filteredPaymentTypes.map(item => item.amount);
  const backgroundColors = filteredPaymentTypes.map(item => item.color);

  const cashCreditData = {
    labels: labels,
    datasets: [{
      data: data,
      backgroundColor: backgroundColors,
      hoverBackgroundColor: backgroundColors
    }]
  };

  const chartOptions = {
    plugins: {
      legend: {
        position: 'bottom', // Position the legend at the bottom
        labels: {
          font: {
            size: 10, // Set the font size smaller
            family: 'Arial', // Specify a font family here
          },
          padding: 15, // Adjust padding between items
          boxWidth: 15, // Set the width of the box
          boxHeight: 15, // Set the height of the box
        },
      },
    },
  };


  // Bar chart data for receivables and payables
  const barData = {
    labels: ['Total Receivables', 'Total Payables'],
    datasets: [{
      data: [totalReceivables, totalPayables],
      backgroundColor: [
        'rgba(54, 162, 235, 0.6)', // Receivables
        'rgba(255, 99, 132, 0.6)'  // Payables
      ],
      borderColor: [
        'rgba(54, 162, 235, 1)', // Receivables border
        'rgba(255, 99, 132, 1)'  // Payables border
      ],
      borderWidth: 2,
      borderRadius: 5 // Optional: Add rounded corners
    }]
  };

  const options = {
    scales: {
      y: {
        ticks: {
          stepSize: 50000, // Adjust the step size as needed
        },
      },
    },
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          generateLabels: (chart) => {
            return [
              {
                text: 'Receivables',
                fillStyle: 'rgba(54, 162, 235, 0.6)',
              },
              {
                text: 'Payables',
                fillStyle: 'rgba(255, 99, 132, 0.6)',
              },
            ];
          },
          padding: 20,
          boxWidth: 14,
          boxHeight: 14,
        },
        onClick: () => {},
        onHover: () => {},
      },
    },
  };


  return (
    <div>
      <Sidebar />
      <div className="dashboard-container">
        <div className="info-container">
          <strong>{companyName}</strong>
          <div>Period: {fromDate} - {toDate}</div>
        </div>
        <div className="cards-container">
          <div className="card-row">
            <Card title="Sales" content={
              <div className="pie-chart" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Pie data={cashCreditData} options={chartOptions} />
                <div style={{ textAlign: 'center' }}>
                  <div className="table-container1">
                    <table className="amount-table">
                      <thead>
                        <tr>
                          <th>Type</th>
                          <th>Amount</th>
                          <th>Change</th>
                          <th>Percentage</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentTotalCash > 0 && (
                          <tr>
                            <td>CASH</td>
                            <td><strong className="amount">Rs. {currentTotalCash}</strong></td>
                            <td className={cashChange < 0 ? 'red' : 'green'}>
                            Rs. {Math.floor(cashChange) >= 0 ? `${Math.floor(cashChange)}` : `${Math.floor(cashChange)}`} 
                            </td>
                            <td className={cashPercentageChange >= 0 ? 'green' : 'red'}>
                              {cashPercentageChange >= 0 ? ` ▲ ${cashPercentageChange}%  ` : `▼ ${-cashPercentageChange}%`}
                            </td>
                          </tr>
                        )}
                        {currentTotalCredit > 0 && (
                          <tr>
                            <td>CREDIT</td>
                            <td><strong className="amount">Rs. {currentTotalCredit}</strong></td>
                            <td className={creditChange < 0 ? 'red' : 'green'}>
                            Rs. {Math.floor(creditChange) >= 0 ? `${Math.floor(creditChange)}` : `${Math.floor(creditChange)}`} 
                            </td>
                            <td className={creditPercentageChange >= 0 ? 'green' : 'red'}>
                              {creditPercentageChange >= 0 ? `▲${creditPercentageChange}% ` : `▼ ${-creditPercentageChange}%`}
                            </td>
                          </tr>
                        )}
                        {currentCard > 0 && (
                          <tr>
                            <td>CARD</td>
                            <td><strong className="amount">Rs. {currentCard}</strong></td>
                            <td className={cardChange < 0 ? 'red' : 'green'}>
                            Rs. {Math.floor(cardChange) >= 0 ? `${Math.floor(cardChange)}` : `${Math.floor(cardChange)}`} 
                            </td>
                            <td className={cardPercentageChange >= 0 ? 'green' : 'red'}>
                              {cardPercentageChange >= 0 ? `▲ ${cardPercentageChange}%  ` : `▼ ${-cardPercentageChange}%`}
                            </td>
                          </tr>
                        )}
                        {currentWallet > 0 && (
                          <tr>
                            <td>WALLET</td>
                            <td><strong className="amount">Rs. {currentWallet}</strong></td>
                            <td className={walletChange < 0 ? 'red' : 'green'}>
                            Rs. {Math.floor(walletChange) >= 0 ? `${Math.floor(walletChange)}` : `${Math.floor(walletChange)}`} 
                            </td>
                            <td className={walletPercentageChange >= 0 ? 'green' : 'red'}>
                              {walletPercentageChange >= 0 ? `▲ ${walletPercentageChange}%` : `▼ ${-walletPercentageChange}%`}
                            </td>
                          </tr>
                        )}
                        {currentCreditNote > 0 && (
                          <tr>
                            <td>CREDIT NOTE</td>
                            <td><strong className="amount">Rs. {currentCreditNote}</strong></td>
                            <td className={creditNoteChange < 0 ? 'red' : 'green'}>
                            Rs. {Math.floor(creditNoteChange) >= 0 ? `${Math.floor(creditNoteChange)}` : `${Math.floor(creditNoteChange)}`} 
                            </td>
                            <td className={creditNotePercentageChange >= 0 ? 'green' : 'red'}>
                              {creditNotePercentageChange >= 0 ? `▲ ${creditNotePercentageChange}% ` : `▼ ${-creditNotePercentageChange}% `}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>

                </div>
              </div>
            } />

            <Card title="Purchase" content={
              <div className="pie-chart" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Pie data={pieData} options={chartOptions} />
                <div style={{ textAlign: 'center' }}>
                  <div className="table-container1">
                    <table className="amount-table">
                      <thead>
                        <tr>
                          <th>Type</th>
                          <th>Amount</th>
                          <th>Change</th>
                          <th>Percentage</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>LOCAL</td>
                          <td><strong className="amount">Rs. {currentLocalAmount}</strong></td>
                          <td style={{ color: localChange < 0 ? 'red' : 'green' }}>
                          Rs. {Math.floor(localChange) >= 0 ? `${Math.floor(localChange)}` : `${Math.floor(localChange)}`}
                          </td>
                          <td style={{ color: localPercentageChange >= 0 ? 'green' : 'red' }}>
                            {localPercentageChange >= 0 ? `▲ ${localPercentageChange}% ` : `▼ ${-localPercentageChange}%`}
                          </td>
                        </tr>
                        <tr>
                          <td>OUTSIDE</td>
                          <td><strong className="amount">Rs. {currentOutsideAmount}</strong></td>
                          <td style={{ color: outsideChange < 0 ? 'red' : 'green' }}>
                          Rs. {Math.floor(outsideChange) >= 0 ? `${Math.floor(outsideChange)}` : `${Math.floor(outsideChange)}`}
                          </td>
                          <td style={{ color: outsidePercentageChange >= 0 ? 'green' : 'red' }}>
                            {outsidePercentageChange >= 0 ? `▲ ${outsidePercentageChange}%` : `▼ ${-outsidePercentageChange}%`}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            } />
            <Card title="Total Outstanding Receivables & Payables" content={
              <div className="bar-graph-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Bar data={barData} options={options} />
                <div style={{ textAlign: 'center', marginTop: '25px' }}>
                  <div className="table-container1">
                    <table className="amount-table">
                      <thead>
                        <tr>
                          <th>Type</th>
                          <th>Amount</th>
                          <th>Change</th>
                          <th>Percentage</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>REC</td>
                          <td><strong className="amount">Rs. {Math.floor(totalReceivables)}</strong></td>
                          <td style={{ color: receivablesChange< 0 ? 'red' : 'green' }}>
                            <strong>Rs. {Math.floor(receivablesChange) >= 0 ? `${Math.floor(receivablesChange)}` : Math.floor(receivablesChange)}</strong>
                          </td>
                          <td style={{ color: receivablesPercentageChange>= 0 ? 'green' : 'red' }}>
                            <strong>{receivablesPercentageChange >= 0 ? `▲ ${receivablesPercentageChange}% ` : `▼ ${-receivablesPercentageChange}%`}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td>PAY</td>
                          <td><strong className="amount">Rs. {Math.floor(totalPayables)}</strong></td>
                          <td style={{ color: payablesChange < 0 ? 'red' : 'green' }}>
                            <strong>Rs. {Math.floor(payablesChange) >= 0 ? `${Math.floor(payablesChange)}` : Math.floor(payablesChange)}</strong>
                          </td>
                          <td style={{ color: payablesPercentageChange >= 0 ? 'green' : 'red' }}>
                            <strong>{payablesPercentageChange >= 0 ? `▲ ${payablesPercentageChange}%` : `▼ ${-payablesPercentageChange}%`}</strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            } />
          </div>
          <div className="card-row">
          <SalesChart/>
          <PurchaseChart/>
          </div>
          <div className="card-row">
            <PaymentChart/>
            <ReceiptChart/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardPage;
