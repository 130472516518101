import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginForm from './Components/LoginForm.js';
import ForgotPassword from './Components/ForgotPassword.js';
import YearSelectPage from "./Components/YearSelectPage.js"
import CompanyDetailsPage from "./Components/CompanyDetailsPage.js"
import DashboardPage from './Components/Dashboard/DashboardPage.js';
import Sidebar from './Components/Dashboard/SideBar.js';
import Outstanding from './Components/Dashboard/Outstanding.js';
import DataTable from './Components/DataTable.js';
import ChangePassword from './Components/Dashboard/ChangePassword.js';
import StockReport from './Components/Dashboard/StockReport.js';
import Stock from './Components/Stock.js';



const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginForm />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/year-select" element={<YearSelectPage />} />
        <Route path="/company-details" element={<CompanyDetailsPage />} />
        <Route path="/dashboard" element={<DashboardPage />} />
        <Route path="/dashboard/sidebar" element={<Sidebar />} />
        <Route path="/Outstanding" element={<Outstanding />} />
        <Route path="/dataTable" element={<DataTable />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/stock-report" element={<StockReport />} />
        <Route path="/stock" element={<Stock />} />
      </Routes>
    </Router>
  );
};

export default App;
