// src/Components/LoginForm.js
import React, { useState } from 'react';
import './css/LoginForm.css';
import logo from './css/images.png';
import { Link, useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';  // Import jwt-decode
import { useAuth } from '../context/AuthContext';  // Import useAuth hook

const LoginForm = () => {
  const [user_email, setUserEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { login } = useAuth();  // Access login function from AuthContext

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!user_email || !password) {
      setError('Both fields are required');
      return;
    }

    setError('');

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_email, password }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Server Error:', errorText);
        setError(errorText || "An error occurred while logging in.");
        return;
      }

      const responseData = await response.json();

      if (!responseData.accessToken) {
        setError("No access token found in the response");
        return;
      }

      localStorage.setItem('accessToken', responseData.accessToken);

      const decodedToken = jwtDecode(responseData.accessToken);
      const { fromDate, toDate } = decodedToken;

      const today = new Date();
      if (today < new Date(fromDate)) {
        setError("Access denied: Subscription has not started yet.");
        return;
      }

      if (today > new Date(toDate)) {
        setError("Access denied: Subscription has expired.");
        return;
      }

      const userId = decodedToken.id;

      localStorage.setItem('user_email', user_email);
      localStorage.setItem('fromDate', fromDate);
      localStorage.setItem('toDate', toDate);
      localStorage.setItem('userId', userId);

      login();  // Update authentication state
      navigate("/year-select");

    } catch (error) {
      console.error('An unexpected error occurred:', error);
      setError('An error occurred while logging in');
    }
  };

  return (
    <div>
      <img src={logo} alt="DeviSoft Logo" className="logo" />
      <form className="login-form-container" onSubmit={handleSubmit}>
        <div>
          <label className="login-form-label" htmlFor="user_email">User Email:</label>
          <input
            className="login-form-input"
            type="email"
            id="user_email"
            value={user_email}
            onChange={(e) => setUserEmail(e.target.value)}
            required
          />
        </div>
        <div>
          <label className="login-form-label" htmlFor="password">Password:</label>
          <input
            className="login-form-input"
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        <button className="login-form-button" type="submit">Login</button>

        <div className="forgot-password">
          <Link to="/forgot-password" className="forgot-password-link">Forgot Password?</Link>
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
