import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';  // Import useNavigate hook for routing
import '../css/SideBar.css';
import logo from '../css/images.png';
import menuIcon from '../css/menu-icon.png';

const Sidebar = () => {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(window.innerWidth <= 768);
  const sidebarRef = useRef(null); // Ref for the sidebar
 

  const handleSignOut = () => {
    localStorage.clear(); // Clear all items from localStorage
    window.location.replace('/'); // Use replace to remove history state so the user cannot press the back button
  };

  // Toggle sidebar state
  const handleToggleSidebar = () => {
    setIsSidebarCollapsed(prev => !prev);
  };

  // Collapse sidebar on link click
  const handleLinkClick = () => {
    setIsSidebarCollapsed(true); // Collapse the sidebar when a link is clicked
  };

  // Handle window resizing (responsive behavior)
  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setIsSidebarCollapsed(true); // Collapse sidebar on small screens
    } else {
      setIsSidebarCollapsed(false); // Expand on larger screens
    }
  };

  // Handle clicks outside the sidebar to collapse it
  const handleClickOutside = useCallback((event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target) && !isSidebarCollapsed) {
      setIsSidebarCollapsed(true); // Collapse the sidebar if clicked outside
    }
  }, [isSidebarCollapsed]);

  useEffect(() => {
    // Set initial state based on screen size
    window.addEventListener('resize', handleResize);
    window.addEventListener('mousedown', handleClickOutside); // Add click outside listener

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('mousedown', handleClickOutside); // Clean up listener
    };
  }, [handleClickOutside]); // Include handleClickOutside in the dependency array

  return (
    <nav ref={sidebarRef} className={`sidebar ${isSidebarCollapsed ? 'collapsed' : ''}`}>
      <header>
        <div className="image">
          <img src={logo} alt="Logo" />
        </div>
        <div className="toggle" onClick={handleToggleSidebar}>
          <img src={menuIcon} alt="Menu Toggle" />
        </div>
      </header>
      <div className="menu-bar">
        <div className="menu">
          <ul className="menu-links">
            <li className="nav-link">
              <Link to="/dashboard" onClick={handleLinkClick}>
                <div className="menu-item-content">
                  <span className={`text nav-text ${isSidebarCollapsed ? 'hidden' : ''}`}>Dashboard</span>
                </div>
              </Link>
            </li>
            <li className="nav-link">
              <Link to="/outstanding" onClick={handleLinkClick}>
                <div className="menu-item-content">
                  <span className={`text nav-text ${isSidebarCollapsed ? 'hidden' : ''}`}>Outstanding</span>
                </div>
              </Link>
            </li>
            <li className="nav-link">
              <Link to="/stock-report" onClick={handleLinkClick}>
                <div className="menu-item-content">
                  <span className={`text nav-text ${isSidebarCollapsed ? 'hidden' : ''}`}>Stock</span>
                </div>
              </Link>
            </li>
            <li className="nav-link">
              <Link to="/change-password" onClick={handleLinkClick}>
                <div className="menu-item-content">
                  <span className={`text nav-text ${isSidebarCollapsed ? 'hidden' : ''}`}>Change Password</span>
                </div>
              </Link>
            </li>
            <li className="nav-link">
              <Link to="/year-select" onClick={handleLinkClick}>
                <div className="menu-item-content">
                  <span className={`text nav-text ${isSidebarCollapsed ? 'hidden' : ''}`}>Switch Company</span>
                </div>
              </Link>
            </li>
          </ul>
        </div>
        <div className="bottom-content">
          <ul>
            <li>
              <button onClick={handleSignOut} className={`logout-button ${isSidebarCollapsed ? 'hidden' : ''}`}>
                Logout
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Sidebar;
